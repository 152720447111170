.about-wrapper {
    width: 60%;
    margin: 30px auto 0;
    text-align: center;
    font-family: Poppins, serif;
    color: var(--text-color);
}

.about-wrapper h1 {
    font-size: 36px;
    opacity: 0;
    transform: translateY(20px);
    transition: 150ms ease-in-out;
}

.about-wrapper h1 span {
    color: var(--active-color);
}

.about-face {
    margin: 20px auto 0;
    height: 180px;
    width: 180px;
    border-radius: 98px;
    background-color: var(--active-color);
    padding: 8px 8px;
}

.about-face img {
    height: 180px;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.about-text {
    font-size: 18px;
    text-align: left;
    margin-top: 30px;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    /*justify-self: center;*/
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.barrier {
    height: 200px;
    width: 1px;
    background-color: var(--text-color);
}

.left, .right {
    width: 42%;
    background-color: var(--about-bg);
    padding: 20px;
    border-radius: 20px;
    font-size: 18px;
    line-height: 28px;
    color: var(--text-color);
}

.about-text span {
    color: var(--specials);
}

.about-text .span-url {
    color: var(--specials);
    cursor: pointer;
}

.about-text a {
    color: var(--specials);
    text-decoration: none;
}

.resume-button {
    margin-top: 10px;
    width: 100%;
    height: 46px;
    border: none;
    outline: none;
    background-color: var(--active-color);
    border-radius: var(--border-radius);
    line-height: 46px;
    font-family: Poppins, serif;
    font-weight: 700;
    color: white;
    cursor: pointer;
    /*transition: 0.5s ease;*/
}

.resume-button:hover {
    opacity: 0.8;
    /*border: 3px solid var(--active-color);*/
    /*background-color: white;*/
    /*color: var(--active-color);*/
    /*line-height: 40px;*/
    /*stroke: var(--active-color);*/
}

.resume-button svg {
    transform: translateY(7px);
    stroke: white;
    margin-right: 5px;
    stroke-width: 1px;
    height: 24px;
    /*transition: 0.5s ease;*/
}

/*.resume-button:hover path{*/
/*    stroke: var(--active-color);*/
/*}*/

.about-wrapper div {
    opacity: 0;
    transform: translateY(20px);
    transition: 150ms ease-in-out;
}

/*.left {*/

/*    transform: translateX(-200px);*/
/*    transition: 200ms ease-in-out;*/


/*}*/

/*.right {*/
/*    transform: translateX(200px);*/

/*}*/

/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .about-wrapper {
    width: 80%;
  }
}

/* Medium screens */
@media screen and (max-width: 768px) {
  .about-wrapper {
    width: 90%;
  }
  
  .about-text {
    flex-direction: column;
    gap: 20px;
  }
  
  .left, .right {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    font-size: 16px;
    line-height: 24px;
  }
  
  .barrier {
    height: 1px;
    width: 80%;
    margin: 10px 0;
  }
  
  .about-wrapper h1 {
    font-size: 30px;
  }
  
  .about-face {
    height: 150px;
    width: 150px;
  }
  
  .about-face img {
    height: 150px;
  }
  
  .resume-button {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

/* Small screens - fix for below 550px */
@media screen and (max-width: 550px) {
  .about-wrapper {
    width: 92%;
    margin: 20px auto 0;
  }
  
  .about-wrapper h1 {
    font-size: 26px;
  }
  
  .about-face {
    height: 130px;
    width: 130px;
  }
  
  .about-face img {
    height: 130px;
  }
  
  .left, .right {
    width: 100%;
    box-sizing: border-box;
    padding: 14px;
    font-size: 15px;
    line-height: 23px;
  }
  
  .resume-button {
    font-size: 13px;
    height: 40px;
    line-height: 40px;
  }
  
  .resume-button svg {
    height: 20px;
    transform: translateY(6px);
  }
}

/* Very small screens */
@media screen and (max-width: 420px) {
  .about-wrapper {
    width: 95%;
    margin: 15px auto 0;
  }
  
  .about-wrapper h1 {
    font-size: 24px;
  }
  
  .about-face {
    height: 110px;
    width: 110px;
    padding: 5px;
  }
  
  .about-face img {
    height: 110px;
  }
  
  .left, .right {
    font-size: 14px;
    line-height: 22px;
    padding: 12px;
  }
  
  .resume-button {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
  }
  
  .resume-button svg {
    height: 16px;
    transform: translateY(4px);
  }
}

/* Extra small screens */
@media screen and (max-width: 350px) {
  .about-wrapper {
    width: 98%;
    margin: 10px auto 0;
  }
  
  .about-wrapper h1 {
    font-size: 22px;
  }
  
  .about-face {
    height: 100px;
    width: 100px;
    padding: 4px;
  }
  
  .about-face img {
    height: 100px;
  }
  
  .left, .right {
    font-size: 13px;
    line-height: 20px;
    padding: 10px;
  }
  
  .resume-button {
    font-size: 11px;
    height: 36px;
    line-height: 36px;
  }
}
