nav {
    height: var(--nav-height);
    background-color: var(--body-bg);
    color: var(--text-color);
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, var(--nav-shadow-opacity)) 0 2px 6px -1px;
}

.nav-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 16px;
    margin-left: 24px;
    border-radius: var(--border-radius);
    font-size: 20px;
    line-height: var(--nav-height);
    cursor: pointer;
    font-family: var(--nav-font);
    font-weight: bold;
}

.nav-header:hover {
    background-color: var(--hover-color);
}

.logo-tick {
    margin-left: 7px;
    height: 20px;
    fill: white;
}

.logo-tick path {
    fill: var(--active-color);
}

.menu {
    list-style-type: none;
    display: flex;
}

.menu li {
    margin: 0 5px;
    display: block;
    font-size: 17px;
    border-radius: var(--border-radius);
}

.menu a {
    padding: 15px 16px;
    line-height: 48px !important;
    font-size: 17px;
    font-family: var(--nav-font);
    cursor: pointer;
    color: var(--text-color) !important;
    text-decoration: none;
}

.menu .active {
    background-color: var(--active-color) !important;
}

.menu .active a {
    color: white !important;
}

.menu li:hover {
    background-color: var(--hover-color);
}

.theme {
    padding: 0 16px;
    margin-right: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    height: 48px;
    border-radius: var(--border-radius);
}

.theme-icon {
    height: 18px;
    margin-right: 5px;
}

.theme-icon path {
    stroke: var(--text-color);
    stroke-width: 2px;
}

.theme-arrow {
    height: 10px;
    margin-left: 5px;
    margin-top: 6px;
}

.theme-arrow path {
    fill: var(--text-color);
}

.theme:hover {
    background-color: var(--hover-color);
}

/* Hamburger Menu - hidden on desktop */
.menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-right: 10px;
    z-index: 2;
}

.menu-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: var(--text-color);
    margin: 3px 0;
    transition: 0.4s;
    border-radius: 2px;
}

/* Responsive styles - only active on mobile */
@media screen and (max-width: 768px) {
    nav {
        padding: 0 10px;
        position: relative;
    }
    
    .menu-toggle {
        display: flex;
    }
    
    .menu {
        position: fixed;
        top: var(--nav-height);
        left: 0;
        width: 100%;
        background-color: var(--body-bg);
        box-shadow: 0 4px 6px rgba(0, 0, 0, var(--nav-shadow-opacity));
        flex-direction: column;
        align-items: center;
        padding: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        z-index: 1000;
    }
    
    .menu.open {
        max-height: 300px;
    }
    
    .menu li {
        width: 100%;
        text-align: center;
        margin: 0;
        border-radius: 0;
    }
    
    .menu a {
        padding: 10px 16px;
        line-height: 30px !important;
    }
    
    .theme-text {
        display: none;
    }
    
    .theme {
        padding: 0 10px;
        margin-right: 5px;
    }
    
    .nav-header {
        margin-left: 5px;
        padding: 0 10px;
    }
    
    /* Hamburger to X animation */
    .menu-toggle.open .bar:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
    }
    
    .menu-toggle.open .bar:nth-child(2) {
        opacity: 0;
    }
    
    .menu-toggle.open .bar:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}

@media screen and (max-width: 480px) {
    .nav-header {
        font-size: 18px;
        padding: 0 5px;
        margin-left: 2px;
    }
    
    .logo-tick {
        height: 16px;
    }
    
    .theme {
        padding: 0 8px;
        margin-right: 2px;
    }
    
    .theme-arrow {
        display: none;
    }
}
