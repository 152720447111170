@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
    --inner-skin-color: #EAEAFF;
    --phone-font: Roboto;
}

.phone {
    height: 608px;
    width: 288px;
    background-color: black;
    border-radius: 32px;
    padding: 16px;
}

.inner-skin {
    background-color: var(--inner-skin-color);
    height: 100%;
    width: 100%;
    border-radius: 16px;
    text-align: center;
}

.notch {
    height: 8px;
    width: 160px;
    margin: 0 auto 24px;
    background-color: black;
    border-radius: 0 0 16px 16px;

}

.phone h1 {
    font-size: 48px;
    font-weight: 700;
    font-family: var(--phone-font);
    color: black;
    line-height: 54px;
    margin-left: 24px;
    text-align: left;
}

.hello {
    text-decoration: underline;
    text-decoration-thickness: 7.2px;
    text-decoration-color: var(--active-color);
    text-underline-offset: 4px;
}

.phone-img {
    height: 180px;
    width: 180px;
    border-radius: 100px;
    background-color: var(--active-color);
    margin: 30px auto 0;
    padding: 8px 8px;
}

.phone-img img {
    height: 180px;
}

.phone-text {
    font-size: 20px;
    color: black;
    text-align: center;
    margin-top: 40px;
}

.phone-btn {
    height: 40px;
    width: 110px;
    text-align: center;
    line-height: 40px;
    color: white;
    background-color: var(--active-color);
    font-size: 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-top: 15px;
    border: none;

}

.phone-btn:hover {
    opacity: 0.7;
    /*border: 2px solid var(--active-color);*/
    /*line-height: 36px;*/
    /*background-color: white;*/
    /*color: var(--active-color);*/
}

.bottom-notch {
    width: 110px;
    height: 8px;
    margin: 0 auto;
    background-color: black;
    border-radius: 16px;
    margin-top: 50px;
}