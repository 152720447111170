.contact-wrapper {
    width: 50%;
    margin: 50px auto 0;
    text-align: center;
    font-family: Poppins, serif;
    color: var(--text-color);
    min-width: 750px;
    /*opacity: 0;*/
    /*margin-top: 200px;*/
    transition: 300ms ease-in-out;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.contact-wrapper h1 {
    font-size: 36px;
}

.contact-wrapper h1 span {
    color: var(--active-color);
}

.contact-main {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.contact-inputs {
    display: flex;
    flex-direction: column;
}

.contact-inputs input {
    width: 348px;
    height: 46px;
    font-size: 16px;
    font-family: Poppins, serif;
    color: var(--text-color);
    margin: 15px 0 0;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    outline: none;
    padding-left: 10px;
    background-color: var(--body-bg);
}

.contact-inputs textarea {
    font-size: 16px;
    font-family: Poppins, serif;
    color: var(--text-color);
    margin: 15px 0 0;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    outline: none;
    padding-left: 15px;
    width: 348px;
    height: 100px;
    background-color: var(--body-bg);
    resize: none;
    padding-top: 10px;
    padding-bottom: 15px;
}

.contact-inputs input:focus, textarea:focus {
    margin-top: 13px;
    border-width: 3px;
    border-style: double;
    margin-bottom: -2px;
    margin-left: -2px;
}


.send-button {
    margin-top: 20px;
    width: 100%;
    height: 46px;
    border: none;
    outline: none;
    background-color: var(--active-color);
    border-radius: var(--border-radius);
    line-height: 46px;
    font-family: Poppins, serif;
    font-weight: 700;
    color: white;
    cursor: pointer;
    /*transition: 0.5s ease;*/
}

.send-button:hover {
    opacity: 0.8 !important;
    /*border: 3px solid var(--active-color);*/
    /*background-color: var(--body-bg);*/
    /*color: var(--active-color);*/
    /*line-height: 40px;*/
    /*stroke: var(--active-color);*/
}

.contact-info {
    margin-top: 20px;
    text-align: left;
}

.contact-info path {
    fill: var(--text-color);
}

.contact-info-header {
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
}

.line-break {
    margin: 25px 0;
    height: 0.5px;
    background-color: var(--text-color);
    width: calc(100% + 70px);
    /*transform: translateX(-20px);*/
}

.contact-footer {
    margin-top: 40px;
}

.contact-footer h1 {
    font-size: 30px;
}

.follow-icons {
    display: flex;
    width: 180px;
    justify-content: space-between;
    margin: 5px auto 0;
}

.follow-icons svg {
    height: 32px !important;
    width: 32px !important;
    cursor: pointer;
    /*transition-timing-function: cubic-bezier(.4, 0, .2, 1);*/
    /*transition-duration: .15s;*/
}

.follow-icons svg:hover {
    transform: scale(1.2) !important;
}

.icon-stroke {
    stroke: var(--text-color);
}

.contact-wrapper h1, .contact-inputs input, .contact-inputs textarea,
.contact-inputs button,
.contact-info div, .contact-footer h1, .contact-footer svg {
    opacity: 0;
    transform: translateY(20px);
    transition: 100ms ease-in-out;
}

/* Responsive styles for mobile - won't affect desktop */
@media screen and (max-width: 768px) {
    .contact-wrapper {
        width: 90%;
        min-width: unset;
        margin: 30px auto 0;
    }
    
    .contact-main {
        flex-direction: column;
        align-items: center;
    }
    
    .contact-inputs {
        width: 100%;
    }
    
    .contact-inputs input, 
    .contact-inputs textarea {
        width: 100%;
        box-sizing: border-box;
    }
    
    .contact-info {
        width: 100%;
        margin-top: 40px;
    }
    
    .line-break {
        width: 100%;
    }
    
    .contact-info-header {
        word-break: break-word;
    }
    
    .contact-wrapper h1 {
        font-size: 30px;
    }
    
    .contact-footer h1 {
        font-size: 26px;
    }
}

@media screen and (max-width: 480px) {
    .contact-wrapper {
        width: 95%;
        margin: 20px auto 0;
    }
    
    .contact-wrapper h1 {
        font-size: 26px;
    }
    
    .contact-footer h1 {
        font-size: 22px;
    }
    
    .contact-info-header {
        font-size: 16px;
    }
    
    .contact-info-text {
        font-size: 14px;
    }
    
    .contact-inputs input {
        height: 40px;
        font-size: 14px;
    }
    
    .contact-inputs textarea {
        font-size: 14px;
        height: 90px;
    }
    
    .send-button {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
    }
    
    .follow-icons {
        width: 160px;
    }
    
    .follow-icons svg {
        height: 28px !important;
        width: 28px !important;
    }
}
